<template>
  <b-field>
    <b-upload
      v-model="tempFile"
      drag-drop
      expanded
      :accept=accept
    >
      <section class="section">
        <div class="content has-text-centered">
          <p>
            <b-icon icon="upload" size="is-large"></b-icon>
          </p>
          <p>{{info.first}}</p>
          <p class="is-size-7">{{info.second}}</p>
          <div class="tags" style="text-align: center;display: inherit">
            <p v-if="tempFile!=null" class="tag is-primary is-large">
              <i class="iconfont icon-photo  mr-2"></i>
              {{ tempFile.name }}
            </p>
          </div>
        </div>
      </section>
    </b-upload>
  </b-field>
</template>

<script>
export default {
  name: "choseIMG",
  props: ['choseFile', 'isFile'],
  model:{
    prop:"choseFile",
    event:"update"
  },
  data() {
    return {
      info: {
        first:"将图片拖到到此处，或点击此处选择图片",
        second:".jpg、.png、.webp、.bmp"
      },
      accept:".png, .jepg, .jpg, .webp, .bmp",
      tempFile: null
    }
  },
  watch: {
    isFile(After) {
      this.tempFile=null
      if (After){
        this.info.first="将文件拖到到此处，或点击此处选择文件"
        this.info.second="支持所有类型格式；若为图片，建议选择'图片类型'"
        this.accept=""
      }
      else{
        this.info.first="将图片拖到到此处，或点击此处选择图片"
        this.info.second="支持.jpg/jpeg、.png、.webp、.bmp"
        this.accept=".png, .jepg, .jpg, .webp, .bmp"
      }
    },
    tempFile(After) {
      this.$emit("update",After)
    }
  },
}
</script>

<style scoped>

</style>
