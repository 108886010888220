import { render, staticRenderFns } from "./adminUploadFile.vue?vue&type=template&id=30081668&scoped=true&"
import script from "./adminUploadFile.vue?vue&type=script&lang=js&"
export * from "./adminUploadFile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30081668",
  null
  
)

export default component.exports