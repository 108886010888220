<template>
  <div class="is-vcentered is-centered">
    <div class="mt-5 mb-5">
      <div class="columns">
        <div class="column is-3">
          <div class="content is-small">
            <article class="block" v-for="(item,key) in left_tips" :key="key">
              <h5 class="heading">
                {{ item.title }}
              </h5>
              <div class="types block">
                <ul>
                  <li v-for="(content,key) in item.content" :key="key">
                    {{ content }}
                  </li>
                </ul>
              </div>
              <hr/>
            </article>
          </div>
        </div>

        <div class="column is-9">
          <b-loading v-model="isLoading" :can-cancel="false"></b-loading>
          <div class="columns">
            <choseIMG v-model="dropFiles" :is-file="uploadSystemMode!='ImageClassify'" class="column"/>
          </div>

          <div class="columns">
            <b-field class="column" label="选择模式">
              <b-select
                v-model="uploadSystemMode"
                placeholder="选择上传模式"
                rounded
                expanded
                size="is-small"
              >
                <option selected value="ImageClassify">图片类别</option>
                <option value="FileClassify">文件类别</option>
              </b-select>
            </b-field>
            <template v-if="uploadSystemMode=='ImageClassify'">
              <b-field class="column" label="选择分类">
                <b-select
                  v-model="uploadAppendChose.imageType"
                  placeholder="选择图片分类"
                  rounded
                  expanded
                  size="is-small"
                >
                  <option v-for="(text,index) in imageTypeList" :key="index" :value="text">{{ text }}</option>
                </b-select>
              </b-field>
              <b-field class="column" label="缩略图生成">
                <b-select
                  v-model="uploadAppendChose.thumbnailPercent"
                  placeholder="缩略图生成"
                  rounded
                  expanded
                  size="is-small"
                >
                  <option value=1>原图1:1尺寸</option>
                  <option value=0.5>原图1/2尺寸</option>
                  <option value=0.25>原图1/4尺寸</option>
                </b-select>
              </b-field>
            </template>
            <template v-else>
              <b-field class="column" label="一级分类">
                <b-select
                  v-model="uploadAppendChose.fileFirstType"
                  placeholder="选择文件一级分类"
                  rounded
                  expanded
                  size="is-small"
                >
                  <option v-for="(text,index) in fileTypeList.first" :key="index" :value="text">{{ text }}</option>
                </b-select>
              </b-field>
              <b-field class="column" label="二级分类">
                <b-select
                  v-model="uploadAppendChose.fileSecondType"
                  :disabled="uploadAppendChose.fileFirstType=='OTHER'"
                  placeholder="二级分类"
                  rounded
                  expanded
                  size="is-small"
                >
                  <option v-for="(text,index) in fileTypeList.second" :key="index" :value="text">{{ text }}</option>
                </b-select>
              </b-field>
            </template>
            <b-field class="column" :label="this.uploadSystemMode=='ImageClassify' ? '图片存储':'文件存储'">
              <b-select
                v-model="uploadAppendChose.attribution"
                :placeholder="this.uploadSystemMode=='ImageClassify' ? '图片存储':'文件存储'"
                rounded
                expanded
                size="is-small"
              >
                <option value="private">私有</option>
                <option value="public">公共</option>
              </b-select>
            </b-field>
          </div>
          <div v-show="dropFiles" class="columns">
            <b-field
              class="column is-12"
              message="建议设置一个好听的友好名称"
              type="is-info"
              label="设置文件友好昵称">
              <b-input rounded size="is-small" v-model="fileFriendName"></b-input>
            </b-field>
          </div>
          <div class="columns">
            <div class="column">
              <div class="buttons">
                <b-button :disabled="!uploadButtonReady" @click="handleSubmit" type="is-primary" rounded expanded>上传
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {get, post} from "@/untils/request";
import choseIMG from "@/components/ImgChose/choseIMG";

export default {
  name: "adminUploadFile",
  components: {choseIMG},
  data() {
    return {
      // 左侧提示
      left_tips: [
        {
          title: "选择模式",
          content: ["支持图片和文件类别", "图片类别：便于在图床标签内进行图片浏览", "文件类别：除了关键词类别外，不公开展示"]
        },
        {
          title: "图片类别",
          content: ["图片类别支持生成缩略图", "支持的图片格式：.BMP、.WEBP、.JPEG、.PNG"]
        },
        {
          title: "文件大小限制",
          content: ["文件大小最大支持50MB"]
        }],
      uploadSystemMode: "ImageClassify",
      uploadAppendChose: {
        imageType: "OTHER",
        thumbnailPercent: 0.5,
        attribution: "public",
        fileFirstType: "OTHER",
        fileSecondType: "OTHER"
      },
      rawFileTypeList: [],
      imageTypeList: [],
      fileTypeList: {
        first: [],
        second: ['OTHER']
      },
      isLoading: false,
      dropFiles: null,
      fileFriendName: null,
      uploadButtonReady: false,
    }
  },
  mounted() {
    this.getTypeList()
  },
  watch: {
    'uploadAppendChose.fileFirstType'(After) {
      this.fileTypeList.second = []
      this.rawFileTypeList
        .filter(item => item.fileType == After)
        .forEach(item => this.fileTypeList.second.push(item.fileTypeDetail))
      this.uploadAppendChose.fileSecondType = this.fileTypeList.second[0]
    },
    dropFiles(After) {
      After != null ? this.fileFriendName = After.name.substring(0, After.name.lastIndexOf(".")) : this.fileFriendName = null
      After != null ? this.uploadButtonReady = true : this.uploadButtonReady = false
    },
  },
  methods: {
    getTypeList() {
      get("fileType/getType")
        .then(e => {
          this.rawFileTypeList = e.data
          this.rawFileTypeList.forEach(item => this.fileTypeList.first.push(item.fileType))
          this.fileTypeList.first = new Set(this.fileTypeList.first)
        })
      get("imageType/getType").then(e => {
        e.data.forEach(v => this.imageTypeList.push(v.imageType))
      })
    },
    handleSubmit() {
      this.isLoading = true
      let formData = new FormData
      let apiURL = "/fileHost/uploadFile"

      if (this.uploadSystemMode != "ImageClassify") {
        formData.append("fileType", this.uploadAppendChose.fileFirstType)
        formData.append("fileTypeDetail", this.uploadAppendChose.fileSecondType)
        formData.append("fileName", this.fileFriendName + "." + this.dropFiles.name.split('.').pop().toLowerCase())
        formData.append("uploadUser", "Mintimate")
      } else {
        formData.append("imageType", this.uploadAppendChose.imageType)
        formData.append("imageName", this.fileFriendName + "." + this.dropFiles.name.split('.').pop().toLowerCase())
        formData.append("thumbnailProportion", this.uploadAppendChose.thumbnailPercent)
        formData.append("uploadUser", "Mintimate")
        apiURL = "/imageHost/uploadImage"
      }
      formData.append("file", this.dropFiles)
      post(apiURL, formData)
        .then(e => {
          console.log(e.data)
          if (e.status == 200) {
            this.$buefy.toast.open({
              duration: 5000,
              message: `上传成功，请添加标签嗷`,
              type: 'is-success',
              pauseOnHover: true
            })
          }
          if (e.status == 206) {
            this.$buefy.toast.open({
              duration: 5000,
              message: `上传终止，图片已存在`,
              type: 'is-warning',
              pauseOnHover: true
            })
          }
          this.dropFiles = null
        })
        .finally(() => {
          this.isLoading = false
          this.dropFiles = null
        })
    }
  },
}
</script>

<style scoped>

</style>
